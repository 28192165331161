import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { concatMap, map } from 'rxjs/operators';
import { Settings, SettingsService } from './settings.service';

@Injectable({
    providedIn: 'root'
})
export class DataService {
    constructor(private http: HttpClient,
        private settingsService: SettingsService) { }

    public getCurrentData() {
        return this.settingsService.getSettings().pipe(concatMap((settings: Settings) => {
            if (!settings) return null;
            return this.http.get(settings.ServerUrl + '/api/queue').pipe(
                map((data: any) => {
                    return data.data;
                })
            );
        }));
    }

    update(ticket: string) {
        const body = {
            Ticket: ticket
        };

        return this.settingsService.getSettings().pipe(concatMap((data: Settings) => {
            return this.http.post(data.ServerUrl + '/api/queue', body).pipe();
        }));
    }

    remove(ticket: string) {
        return this.settingsService.getSettings().pipe(concatMap((data: Settings) => {
            return this.http.delete(data.ServerUrl + '/api/queue/' + ticket).pipe()
        }));
    }
}